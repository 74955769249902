import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const ThankyouHeadline = styled.h1`
  margin-top: 10rem;
  margin-bottom: 1rem;
`

const ThankyouContainer = styled.div`
  margin-top: 15rem;
  padding-bottom: 20rem;
  .bloc-btn {
    display: inline-block;
    padding: 1.7rem 2.5rem;
  }
`

const ThanksPage = () => (
  <Layout backgroundColor="#f7f7f7" theme="theme-dark" backgroundGradient = "background-gradient">
    <SEO title="Thank you." keywords={[`gatsby`, `application`, `react`]} />

    <ThankyouContainer>
      <div className="container thankyou-container">
        <div className="row align-items-start">
          <div className="col">
            <ThankyouHeadline className="header-text thankyou-hero">
              THANK YOU
            </ThankyouHeadline>
            <div className="work-line"></div>
            {/* <p className="leader-paragraph mb-20">
              Please check your inbox for a link.
            </p> */}
            <Link to="/" className="btn-border bloc-btn">
              RETURN TO HOME PAGE
            </Link>
          </div>
        </div>
      </div>
    </ThankyouContainer>
  </Layout>
)

export default ThanksPage
